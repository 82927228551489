import $ from "jquery";

import "bootstrap";

import "../stylesheets/application";

import "../application/components/navbar/navbar";

import "../application/components/footer/footer";

import "../application/components/home/carousel/carousel";

import "../application/components/home/product_groups/product_group";

import "../application/components/product_groups/maatwerk/maatwerk";

import "../application/components/product_groups/product_group/product_group";

import "../application/components/product_groups/product/product";

import "../application/components/projects/project";

import "../application/components/social_media_icons/social_media_icons";

import "../application/components/blogs/sidebar/sidebar";

import "../application/components/contact_popout/contact_popout";

import "../application/components/cookiebar/cookiebar";

import "../application/components/video/video";

import "../application/scripts/event_listeners.js";

import "../application/scripts/forms.js";

import "../application/scripts/images.js";

require("@rails/ujs").start();

window.jQuery = $;

window.$ = $;
