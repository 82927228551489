import './cookiebar.scss';

function set_cookies() {
  let accepted_cookies = localStorage.getItem('accepted_cookies');
  if (accepted_cookies == null) {
    show_cookiebar();
  }
  if (accepted_cookies == 'personal') {
    set_facebook_pixel();
    show_youtube_videos();
    hide_youtube_placeholders();
  }
}

function set_facebook_pixel() {
  !function (f, b, e, v, n, t, s) {
    if (f.fbq) return; n = f.fbq = function () {
      n.callMethod ?
        n.callMethod.apply(n, arguments) : n.queue.push(arguments)
    };
    if (!f._fbq) f._fbq = n;
    n.push = n;
    n.loaded = !0;
    n.version = '2.0';
    n.queue = [];
    t = b.createElement(e);
    t.async = !0;
    t.src = v;
    s = b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t, s)
  }
  (window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
  fbq('init', '356480602304801');
  fbq('track', 'PageView');
}

function show_youtube_videos() {
  const videos = Array.from(
    document.getElementsByClassName("iframe")
  );
  if (videos.length > 0) {
    videos.forEach(video => {
      video.src = video.dataset.src;
      video.classList.remove('d-none');
    });
  }
}

function hide_youtube_placeholders() {
  const placeholders = Array.from(
    document.getElementsByClassName("iframe-placeholder")
  );
  if (placeholders.length > 0) {
    placeholders.forEach(placeholder => {
      placeholder.classList.add('d-none');
    });
  }
}

function show_cookiebar() {
  document.getElementById('cookiebar').classList.remove('d-none');
}

function hide_cookiebar() {
  document.getElementById('cookiebar').classList.add('d-none');
}

function accepted_personal_cookies() {
  localStorage.setItem('accepted_cookies', 'personal');
  set_facebook_pixel();
  show_youtube_videos();
  hide_youtube_placeholders();
}

function show_submit_text() {
  if (document.getElementById('accept_cookies_submit') !== undefined && document.getElementById('accept_cookies_submit') !== null) {
    document.getElementById('accept_cookies_submit').classList.remove('d-none');
  }
}

document.addEventListener('click', event => {
  if (event.target.className.includes('accept_cookies')) {
    hide_cookiebar();

    if (document.querySelector('input[name="cookies"]:checked') != null) {
      const selected_option = document.querySelector('input[name="cookies"]:checked').value;
      if (selected_option == 'standard') {
        localStorage.setItem('accepted_cookies', 'standard');
      } else {
        accepted_personal_cookies();
      }
    } else {
      accepted_personal_cookies();
    }
    show_submit_text();
  }
});

window.addEventListener("load", function () {
  set_cookies();
});
