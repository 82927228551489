document.addEventListener('change', event => {
  if (event.target.className.includes('check_other_option')) {
    if (event.target.value == 'Anders, namelijk:') {
      hideSelectTextField(event.target.id);
    } else {
      showSelectTextField(event.target.id);
    }
  }
});

function hideSelectTextField(id) {
  document.getElementById(id + "_other").classList.remove("d-none");
  document.getElementById(id + "_other").focus();
}

function showSelectTextField(id) {
  document.getElementById(id + "_other").classList.add("d-none");
}
