import './contact_popout.scss';

if (document.getElementById('contactButton') !== null) {
  document.getElementById('contactButton').addEventListener('click', event => {
    if (document.getElementById('contactPopout').classList.contains('active')) {
      document.getElementById('contactPopout').classList.remove('active');
      Array.from(document.querySelectorAll('.text-danger')).forEach((el) => el.remove());
      Array.from(document.querySelectorAll('.border-danger')).forEach((el) => el.classList.remove('border-danger'));
    } else {
      document.getElementById('contactPopout').classList.add('active');
    }
  })
}