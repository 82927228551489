function setBackgroundImage() {
  const containers = Array.from(
    document.getElementsByClassName("set-bgImg")
  );
  if (containers.length > 0) {
    const mobileQuerie = window.matchMedia("(max-width: 768px)");
    if (mobileQuerie.matches) {
      containers.forEach(element => {
        const mobileImage = element.dataset.bgmobile;
        element.style.backgroundImage = 'url(' + mobileImage + ')';
      });
    } else {
      containers.forEach(element => {
        const desktopImage = element.dataset.bgdesktop;
        element.style.backgroundImage = 'url(' + desktopImage + ')';
      });
    }
  }
}

window.addEventListener("load", function () {
  setBackgroundImage();
});